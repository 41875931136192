
import { defineComponent, PropType } from 'vue';
import { HeaderModel, HeaderNaviTopicModel } from './model';
import VarmaHeaderDesktopTopicMenu from './VarmaHeaderDesktopTopicMenu.vue';
import VarmaHeaderDropDown from './VarmaHeaderDropDown.vue';
import VarmaHeaderItem from './VarmaHeaderItem.vue';
import VarmaHeaderSearch from './VarmaHeaderSearch.vue';

export default defineComponent({
    components: {
        VarmaHeaderDesktopTopicMenu,
        VarmaHeaderDropDown,
        VarmaHeaderItem,
        VarmaHeaderSearch,
    },
    props: {
        model: { type: Object as PropType<HeaderModel>, required: true },
    },
    data() {
        return {
            contactOpen: false,
            languageOpen: false,
            topic: null
        } as {
            contactOpen: boolean,
            languageOpen: boolean,
            topic: null | HeaderNaviTopicModel,
        };
    },
    mounted() {
        this.$watch(() => this.contactOpen, (open: boolean) => {
            if (open) {
                this.languageOpen = false;
            }
        });

        this.$watch(() => this.languageOpen, (open: boolean) => {
            if (open) {
                this.contactOpen = false;
            }
        });

        document.addEventListener('keyup', (ev: KeyboardEvent) => {
            if (ev.code === 'Escape') {
                if (this.topic) {
                    let index = this.model.topics.findIndex(this.isTopic);
                    let node = (this.$refs.topics as HTMLElement[])[index];
                    if (node) {
                        node.focus();
                    }

                    this.topic = null;
                }
            }
        });

        document.addEventListener('click', (ev: MouseEvent) => {
            this.handleElementFocus(ev.target as Node);
        });

        document.addEventListener('focusin', (ev: FocusEvent) => {
            this.handleElementFocus(ev.target as Node);
        });
    },
    methods: {
        onMainActionClick(): void {
            const mainAction = this.model.mainAction!;

            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push(
                { 'name': mainAction.analyticsName, 'action': 'click', 'event': mainAction.analyticsEvent }
            );
        },
        isTopic(topic: HeaderNaviTopicModel): boolean {
            return topic?.url == this.topic?.url;
        },
        toggleTopic(topic: HeaderNaviTopicModel): void {
            if (this.isTopic(topic)) {
                this.topic = null;
            } else {
                this.topic = topic;
            }
        },
        isElementOutside(element: Node): boolean {
            while (element !== null) {
                if (element == this.$refs.top || element == this.$refs.main) {
                    return false;
                }
                element = element.parentElement as Node;
            }

            return true;
        },
        handleElementFocus(element: Node) {
            if (this.isElementOutside(element)) {
                this.topic = null;
            }
        }
    }
});
