import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6691768"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "aria-expanded", "aria-controls"]
const _hoisted_3 = {
  key: 0,
  class: "varma-expansion-panel__heading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["varma-expansion-panel", { 'varma-expanded': _ctx.isExpanded, 'varma-expansion-panel-boxed': _ctx.boxed, 'varma-expanded-single': _ctx.isSingle, 'varma-expansion-panel--no-bg': _ctx.noBackground }])
  }, [
    _createElementVNode("div", {
      id: _ctx.anchorId,
      class: "linkable-anchor"
    }, null, 8, _hoisted_1),
    _createElementVNode("button", {
      id: _ctx.accordionId + '-toggle',
      "aria-expanded": _ctx.isExpanded,
      "aria-controls": _ctx.accordionId,
      class: _normalizeClass(["varma-expansion-panel__header", { 'varma-expanded': _ctx.isExpanded }]),
      "data-cy": "varma-accordion-toggle",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isExpanded = !_ctx.isExpanded))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["varma-expansion-panel__indicator", { 'varma-expanded': _ctx.isExpanded }])
      }, [
        _createVNode(_component_varma_icon, {
          name: "arrow-down",
          size: "S",
          weight: "bold",
          color: "black",
          "vertical-align": "middle"
        })
      ], 2),
      (_ctx.heading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.heading), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
    ], 10, _hoisted_2),
    _createVNode(_Transition, {
      name: "expand",
      onEnter: _ctx.enter,
      onAfterEnter: _ctx.afterEnter,
      onLeave: _ctx.leave
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default", { isExpanded: _ctx.isExpanded }, undefined, true)
        ], 512), [
          [_vShow, _ctx.isExpanded]
        ])
      ]),
      _: 3
    }, 8, ["onEnter", "onAfterEnter", "onLeave"])
  ], 2))
}